import React, { useState, useContext, useEffect } from 'react'
import Builder from '../builder/Builder';
import { Header } from './_shared/ModalHeader';
import { GameStateContext } from '../../context/context';
import Draggable from 'react-draggable'
import './_shared/viewer.css';

const Folder = ({ icon, close }) => {
    const context = useContext(GameStateContext);

    return (
        <Draggable handle=".hak-modal-header-label" bounds={"#draggable-bounds"}>
            <div className={"hak-modal"} id={"folder-bounds"}>
                <Header icon={icon} close={close} />
                <Builder config={icon.children} />
            </div>
        </Draggable>
    )
}

export default Folder;