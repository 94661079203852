import Div100vh from 'react-div-100vh';
import { useAuth } from 'oidc-react';
import './login.css';
import config from "../_shared/config";
import { useEffect,  useState } from 'react';
//useMemo,
import Desktop from './Desktop';

const REACT_APP_HAK_DBD_PWD = process.env.REACT_APP_HAK_DBD_PWD;
 
const {
    base_url,
    assets: {
        hakdbd_desktopbackground
    } } = config;

const Login = (props) => {
    const auth = useAuth();
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [userLoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        setError("");
    }, [password]);

    const isHakLoggedin = true; //Bypassing ID Services AUTH
    //useMemo(() =>  auth && auth.userData, [auth && auth.userData]);

    const handleLogout = () => {
        setLoggedIn(false);
    }
    const handleEnter = () => {
        if (isHakLoggedin) {
            if (password.toUpperCase() === REACT_APP_HAK_DBD_PWD) {
                setPassword("");
                setLoggedIn(true);
            }
            else {
                setError("Invalid Password");
            }
        }
        else
            auth.signIn()
    }

    if (userLoggedIn) {
        return (<Div100vh dontresize="true" className="vh-100 vw-100 hak-desktop" >
            <Desktop handleLogout={handleLogout} />
        </Div100vh>)
    }
    else if (auth.isLoading) {
        return (<div>loading... </div>)
    }
    else {
        return (<Div100vh dontresize="true" className="vh-100 vw-100 hak-desktop" >
            <img className={"hak-desktop-background"} alt={"hakdbd_desktopbackground"} src={base_url + hakdbd_desktopbackground} />
            {isHakLoggedin ?
                <>
                    <input
                        className='hak-desktop-pwd-input'
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder={"Password"}
                        onKeyUp={e => e.key === 'Enter' && handleEnter()} />
                    <span className='text-error'>{error}</span>
                </>
                : null}
            <button className={"hak-login-button"} onClick={handleEnter}>ENTER</button>
        </Div100vh>)
    }
};

export default Login;