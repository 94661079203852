import React, { useEffect, useState } from 'react';
import { GameStateContext } from './context';
import { useAuth } from 'oidc-react';

export function GameStateProvider(props) {

    const API_URL = process.env.REACT_APP_GSM_APIURL;
    const auth = useAuth();
    const local_key = "dead-below-deck"
    const [gameState, setGameState] = useState(null);


    const validateExpToken = () => {
        //if token is expired for whatever reason, api call fails with 401, ensure user is logged in first
        const expirationTime = auth.userData.expires_at * 1000
        if (Date.now() >= expirationTime) {
            auth.userManager.signoutRedirect();
        }
    }

    const initialState = { state: { lastUpdated: null, unlocked: [] } }

    const getLocalGSM = () => {
        let val = localStorage.getItem(local_key);
        return (val && JSON.parse(val)) || initialState;
    }

    useEffect(() => {
        const fetchData = async () => {
            validateExpToken();
            let unlocked = [];
            let localData = getLocalGSM();

            try {
                const response = await fetch(API_URL, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + auth.userData?.access_token
                    }
                });
                let remoteData = await response.json();

                if (response.status !== 200 || localData?.state?.lastUpdated > remoteData?.state?.lastUpdated) {
                    unlocked = localData?.state?.unlocked || [];
                }
                else {
                    unlocked = remoteData?.state?.unlocked || [];
                }
            }
            catch (e) {
                unlocked = localData?.state?.unlocked || [];
            }

            setGameState(unlocked)
        }

        if (auth.userData) fetchData();

    }, [auth.userData?.access_token]);


    const postState = (unlocked) => {

        validateExpToken();

        try {
            (async () => {
                await fetch(API_URL, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + auth.userData?.access_token
                    },
                    body: JSON.stringify({ "unlocked": unlocked, "lastUpdated": Date.now() })
                });
            })();
        }
        finally {
            localStorage.setItem(local_key, JSON.stringify({ "state": { "unlocked": unlocked, "lastUpdated": Date.now() } }));
        }
    }

    return (
        <GameStateContext.Provider
            value={{
                gameState: gameState,
                addState: (newState) => {
                    let state = gameState || [];

                    postState([...state, newState]);
                    setGameState([...state, newState]);
                },
                resetState: () => {
                    postState([]);
                    setGameState([]);
                }
            }}
        >
            {props.children}
        </GameStateContext.Provider>
    );
}