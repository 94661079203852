import config from "../../_shared/config";
const { base_url } = config;

const icons = [
      {
        id: "witness-statement",
        icon: base_url + "/assets/hakdbd-witnessstatementsfoldericon.svg",
        label: "Witness Statements",
        type: "folder",
        children: [
          {
            id: "Hornigolder-Statement",
            icon: base_url + "/assets/hakdbd-hornigolder-statementicon.svg" ,
            label: "Hornigolder-Statement",
            type: "video",
            
          },  
          {
            id: "Read-Statement",
            icon: base_url + "/assets/hakdbd-read-statementicon.svg" ,
            label: "Read-Statement",
            type: "video",
           
          }, 
          {
            id: "Teache-Statement",
            icon: base_url + "/assets/hakdbd-teache-statementicon.svg" ,
            label: "Teache-Statement",
            type: "video",
            
          }, 
          {
            id: "Rogerstone-Statement",
            icon: base_url + "/assets/hakdbd-rogerstone-statementicon.svg" ,
            label: "Rogerstone-Statement",
            type: "video",
           
          }, 
          {
            id: "WBonnys-Statement",
            icon: base_url + "/assets/hakdbd-bonnys-statementicon.svg" ,
            label: "WBonnys-Statement",
            type: "video",
          },  
          {
            id: "Rackhams-Statement",
            icon: base_url + "/assets/hakdbd-rackhams-statementicon.svg" ,
            label: "Rackhams-Statement",
            type: "video",
          },  
        ]
    },
    {
        id: "drive",
        icon: base_url + "/assets/hakdbd-drivefoldericon.svg",
        label: "Drive",
        type: "folder",
        children: [
          {
            id: "Group Text Chain",
            icon: base_url + "/assets/hakdbd-grouptextchainicon.svg" ,
            label: "Group Text Chain",
            type: "pdf",

          },  
          {
            id: "Wedding Vision Board",
            icon: base_url + "/assets/hakdbd-weddingvisionboardicon.svg" ,
            label: "Wedding Vision Board",
            type: "pdf"
          }, 
          {
            id: "Captain's Log",
            icon: base_url + "/assets/hakdbd-captainslogicon.svg" ,
            label: "Captain's Log",
            type: "pdf"
          }, 
          {
            id: "Training Manual",
            icon: base_url + "/assets/hakdbd-trainingmanualicon.svg" ,
            label: "Training Manual",
            type: "pdf"
          }, 
        ]
    }
]

export default icons;