import React, { useContext, useState, useImperativeHandle, forwardRef } from 'react';
import { GameStateContext } from '../../context/context';
import { getComponentTag } from '../../_shared/utils';
import './builder.css';

const Builder = forwardRef(({ config }, ref) => {

    const [current, setCurrent] = useState(null);
    const context = useContext(GameStateContext);

    useImperativeHandle(ref, () => ({
        closeWindows() {
            setCurrent(null);
        }
    }));

    const close = () => {
        setCurrent(null);
    }

    const iconClick = (icon) => {
        setCurrent(icon);
    }

    const setIcon = (icon) => {

        if (icon?.lock?.type === "hide" && !context?.gameState?.includes(icon.lock.state)) {
            return <div key={icon.id}></div>
        }
        else {
            return <li key={icon.id} className={'mb-4 ' + icon.type}>
                <div className="desktop-icon" alt={icon.id}>
                    <img onClick={() => iconClick(icon)} src={icon.icon} alt={icon.label} />
                </div>
            </li>
        }
    }

    return (
        <>
            <ul className="list-unstyled list desktop-icons-container">
                {config.map((icon) => (
                    setIcon(icon)
                ))}
                <li>{current?.type && getComponentTag(current, close)}</li>
            </ul>
        </>);

});

export default Builder;