const base_url = window.location.origin;
const config = {
    base_url,
    assets: {
        hakdbd_desktopbackground: '/assets/hakdbd-desktopbackground.svg',
        hakdbd_jenhornigolderchat_desktop: '/assets/hakdbd-jenhornigolderchat-desktop.svg',
        hakdbd_jenhornigolderchat_mobile: '/assets/hakdbd-jenhornigolderchat-mobile.svg',
    }
}

export default config