export const contents = {
    "Group Text Chain": [
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-grouptextchain-pg1.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-grouptextchain-pg2.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-grouptextchain-pg3.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-grouptextchain-pg4.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-grouptextchain-pg5.jpg"
    ],
    "Wedding Vision Board": ["https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-weddingvisionboard.jpg"],
    "Captain's Log": ["https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-captainslog-pg1.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-captainslog-pg2.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-captainslog-pg3.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-captainslog-pg4.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-captainslog-pg5.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-captainslog-pg6.jpg"],
    "Training Manual": ["https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg1.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg2.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg3.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg4.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg5.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg6.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg7.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg8.jpg",
        "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-trainingmanual-pg9.jpg"],
    "solution audio": "https://cdn.huntakiller.com/dead-below-deck/media/hakdbd-thankyouaudio.mp3",
    "Hornigolder-Statement": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-hornigolder-statement.mp4",
    "Read-Statement": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-read-statement.mp4",
    "Teache-Statement": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-teache-statement.mp4",
    "Rogerstone-Statement": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-rogerstone-statement.mp4",
    "WBonnys-Statement": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-bonnys-statement.mp4",
    "Rackhams-Statement": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-rackhams-statement.mp4",
    "epilogue news clip": "https://cdn.huntakiller.com/dead-below-deck/media/video/hakdbd-newsclip.mp4",

}