import React from 'react'
import { Header } from './_shared/ModalHeader';
import './_shared/viewer.css';
import Draggable from 'react-draggable';
import { contents } from '../contents/content';

const Viewer = ({ icon, close }) => {

console.log(icon.id, contents[icon.id]);
 return (<Draggable handle="hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
 <div className={"hak-modal-video"}>
     <Header icon={icon} close={close} secondLevel={true} />
                <video controls width="100%" height="100%" >
                    <source src={contents[icon.id]} />
                    This browser does not support the HTML5 video element.
                </video>
            </div>
        </Draggable>
    )
}

export default Viewer;