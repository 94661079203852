import React, { Suspense } from 'react';
//, useState
import { AuthProvider } from 'oidc-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { oidcConfig } from './oidc';
import { GameStateProvider } from './context/provider'
import Login from './components/Login';

let oidc = oidcConfig[process.env.REACT_APP_ENVIRONMENT]

function App() {
  return (
    <AuthProvider autoSignIn={false} {...oidc}>
      <GameStateProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Login />
        </Suspense>
      </GameStateProvider>
    </AuthProvider>
  );
}

export default App;
