import { useState, useEffect } from "react";
import { addResponseMessage } from "react-chat-widget";
import Folder from "../components/viewer/Folder";
import Video from "../components/viewer/Video";
import PDFViewer from "../components/viewer/Pdf";


export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);

        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

export const qna = (question, episode, cta) => {
    return fetch(`https://api.huntakiller.com/qna/dead-below-deck`, {
        method: 'POST',
        body: JSON.stringify({
            "question": question,
            "top": 1,
            "scoreThreshold": 80
        }),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
};

export const addDelayedResponseMessage = (message, index) => {
    sleep(1500 * index).then(() => {
        addResponseMessage(message);
    });
}


export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const replaceTokens = (chat) => {
    return chat.replace("{{Correct}}", "").replace("{{Incorrect}}", "").replace("{", "").replace("}", "");
}

export const getComponentTag = (icon, close) => {
    let components = {
        folder: Folder,
        video : Video,
        pdf : PDFViewer

    };

    const TagName = components[icon.type];
    return <TagName icon={icon} close={close} />
}

const dead_below_deck_saved_messages = "dead_below_deck_saved_messages";
export const getSavedMessages = () => {
    return JSON.parse(localStorage.getItem(dead_below_deck_saved_messages) || "[]");
}

export const saveMessages = (messages = []) => {
    localStorage.setItem(dead_below_deck_saved_messages, JSON.stringify(messages));
}