import React, { useState } from 'react'
import { contents } from '../contents/content';
import { Header } from './_shared/ModalHeader';
import './_shared/viewer.css';
import Draggable from 'react-draggable';


const PDFViewer = ({ icon, close }) => {

  const [loading, setLoading] = useState(true);
  const imageLoaded = () => setLoading(false);
  const nodeRef = React.useRef(null);
  const imgRef = React.useRef(null);

  const [current, setCurrent] = useState(0);
  const backClick = () => { 
    current > 0 && setCurrent(current - 1); 
    let imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  }
  const fwClick = () => { 
    current < contents[icon.id].length - 1 && setCurrent(current + 1); 
    let imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  }

  const [imgWidth, setImgWidth] = useState(100)
  const [fetching, setFetching] = useState(false)

  const zoom = (zoomIn) => {
    let widthPercent = imgWidth;
    widthPercent = zoomIn ? Math.max(widthPercent - 20, 100) : Math.min(widthPercent + 20, 350);
    setImgWidth(widthPercent);
  };



  const download = (url, name, e) => {
    e.preventDefault();
    if (!fetching) {
      setFetching(true);
      fetch(url + "?crossorigin")
        .then(response => response.blob())
        .then(blob => {
          setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";
          a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch(console.log("Error downloading image."));
    }
  };

  return (<Draggable handle=".hak-modal-header-label-secondlevel" bounds={"#folder-bounds"}>
    <div className={"hak-modal-pdf"}>
      <Header icon={icon} close={close} secondLevel={true} />
      {loading && <div className={"center"}>Loading..</div>}
      {<div style={{ "overflow": "auto", "maxHeight": "75vh" }}>
        <img
          style={{ width: imgWidth + "%", display: loading ? 'none' : 'block' }}
          onLoad={imageLoaded}
          src={contents[icon.id][current]}
          alt={"File not available!"}
        />
      </div>}
        <div className={"center"} style={{ "maxHeight": "5vh" }} >
        <div className="d-flex justify-content-between p-1">
            {contents[icon.id].length > 1 && <div style={{borderBottom: "0px"}}>
              <button className={"btn btn-sm btn-dark"} onClick={backClick}>{" < "}</button>
              <span className={"p-2 mt-3"}>{"page: "}{current + 1}{"/"}{contents[icon.id].length}</span>
              <button className={"btn btn-sm btn-dark"} onClick={fwClick}>{" > "}</button>
            </div>}
           
            <div style={{borderBottom: "0px"}}>
              <button disabled={imgWidth >= 350} className={"btn btn-sm"} onClick={() => zoom(false)}><img style={{ width: "20px" }} src="https://cdn.huntakiller.com/huntakiller/s10/zoomin-icon.svg" /></button>
              <button disabled={imgWidth <= 100} className={"btn btn-sm"} onClick={() => zoom(true)}><img style={{ width: "20px" }} src="https://cdn.huntakiller.com/huntakiller/s10/zoomout-icon.svg" /></button>
              <button className={"btn btn-sm"} onClick={(e) => download(contents[icon.id][current], icon.label, e)}><img style={{ width: "17px" }} src="https://cdn.huntakiller.com/huntakiller/s10/download-icon.svg" /></button>
            </div>
          </div>
          </div>
    </div>
  </Draggable>
  );
}

export default PDFViewer;