import { XLg } from 'react-bootstrap-icons';
import './viewer.css'

export const Header = ({ icon, close, secondLevel }) => {
    let classLabel = `${secondLevel ? "hak-modal-header-label-secondlevel" : "hak-modal-header-label"}`
    return (
        <div className={"hak-modal-header"}>
            <div style={{ width: "100%", float: "left", textAlign: "center" }} className={classLabel}>{icon.label}</div>
            {close && <div style={{ width: "auto", float: "right", paddingRight: "5px", cursor: "pointer", position: "absolute", top: "0", right: "0", zIndex: "10" }}><XLg onClick={close} /></div>}
        </div >)
}